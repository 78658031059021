*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 18px;
  font-style: normal;
  background-color: #c19a6b;
}

h1 {
  text-align: center;
}
.hOmeH6 {
  font-size: 15px;
  font-style: italic;
  font-weight: bolder;
  text-align: center;
}

.aBoutUsH4 {
  font-size: 30px;
  font-style: italic;
  text-align: center;
  margin-top: 75px;
  margin-bottom: 75px;
}

.eVentsH4 {
  font-size: 45px;
  font-style: italic;
  text-align: center;
  margin-top: 75px;
  margin-bottom: 75px;
}
.aBoutUsP {
  font-size: 16px;
  font-style: italic;
  text-align: left;
  margin-top: 65px;
  margin-left: 150px;
  margin-bottom: 75px;
}

p {
  font-size: small;
  font-style: oblique;
}

.pAboutUs {
  font-size: small;
  font-size: medium;
  text-align: left;
  padding: 2%;
}

.pFooter {
  font-size: 20px;
  font-style: italic;
  font-weight: bolder;
  margin-top: 45px;
}
.pFooter1 {
  font: 30px;
  font-style: italic;
  font-weight: bolder;
  margin-bottom: 30px;
}

.logo-image-1 {
  width: 120px;
  height: 100px;
  margin: 15px;
}

.marchevent {
  width: 500px;
  height: 650px;
  margin: 5px auto 20px auto;
  background-color: rgb(242, 233, 222);
}

.patient-image-1 {
  width: 400px;
  height: 160px;
  margin-top: 75px;
  padding: 3%;
}

.patient-image-2 {
  width: 460px;
  height: 260px;
}

.patient-exp-1 {
  width: 260px;
  height: 160px;
  margin-top: 75px;
  padding: 3%;
  justify-content: center;
}

.footer-img-fb {
  width: 50px;
  height: 50px;
}
.footer-img-ins {
  width: 35px;
  height: 35px;
}
.qrcode {
  width: 190px;
  height: 190px;
  padding-bottom: 2%;
}

.nurse-image-1 {
  width: 490px;
  height: 280px;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  align-content: center;
}

.hwelcome {
  margin: 30px 0px;
  padding: auto;
  text-align: center;
}

.htab:hover {
  box-shadow: 3px 4px 3px rgb(88, 86, 86);
  margin: 1px;
}

footer {
  text-align: center;
  margin-top: 30px;
}

.navbar {
  background-color: #add8e6;
  background-size: auto;
}

div.row {
  background-color: rgb(242, 233, 222);
}

.patient-needs {
  justify-content: space-between;
}

.contact {
  text-align: center;
}

.uList {
  font-size: 12px;
  margin-right: 7%;
  margin-left: 7%;
}

.eVentOl {
  font-size: 22px;
  margin-left: 200px;
}

.center {
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 50%;
}

.Gallery {
  width: 100vw;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}

img {
  width: 300px;
  height: 200px;
  margin: 10px;
}

